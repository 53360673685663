//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'
import BenefitsBoomerang from '../../components/marketing/benefitsBoomerang'

const useCaseData = [
  {
    title: 'Deliver info to stakeholders via SMS',
    titleWeight: 'medium',
    titleColor: 'white-1',
    description:
      'Text messaging is the ideal way to communicate with your on-the-go employees and contractors because there are no apps to download or install',
    descriptionColor: 'white-3',
    image: 'usecaseConstructionSolution',
    iconShow: true,
    iconContext: 'hammer',
    // iconColor: 'sky',
    // lineClass: 'green-1',
    // bgcolor: 'dkgreen-8',
    // border: 'border-top-2-dkgreen-1',
    bggradient: 'cousteau',
  },
]
const featureData = {
  title: 'Engage on-the-go construction employees & contractors',
  titlecolor: 'black-4',
  mode: 'triovertical',
  bgcolor: 'grayblue-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Deliver training and assessment',
          titlecolor: 'dkorange-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'dkorange-1',
          iconname: 'graduation',
          iconcolor: 'dkorange',
          path: '/platform/employee-training/',
          description:
            'Deliver bite-sized training modules and knowledge quizzes via SMS',
        },
        {
          title: 'Ensure a safe workplace',
          titlecolor: 'blue-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'blue-1',
          iconname: 'shield',
          iconcolor: 'blue',
          path: '/platform/safety-compliance/',
          description:
            'Provide a confidential reporting tipline via text for unsafe or hazardous workplace situations',
        },
        {
          title: 'Improve worksite morale',
          titlecolor: 'dkgreen-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'dkgreen-1',
          iconname: 'chatbot',
          iconcolor: 'dkgreen',
          path: '/platform/pulse-surveys/',
          description:
            "Gauge the morale of your workers with SMS pulse surveys that identify what's going well, and what to improve",
        },
      ],
    },
  ],
}
const About = (props) => (
  <Layout>
    <Helmet
      title="Storylava - Construction - Visual Content Marketing"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how Storylava can help you drive more employee engagement at your construction company',
        },
      ]}
    />

    <NavSubUsecases />

    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-xl-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium blue-1">
            Connect with construction workers using texting
          </h1>
          <p class="display-4">
            With construction workers always on-the-go, drive ROI and more
            effective communication via text messaging
          </p>
        </div>
      </div>
    </div>

    <AlternatingCallout featureData={useCaseData} />

    {/* SELECTED FEATURES */}
    <FeatureQuad featureData={featureData} />
    {/* END SELECTED FEATURES */}
  </Layout>
)

export default About
